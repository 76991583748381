import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import "./viewAppointments.css";
import HeaderComponent from "../headerComponent/headerComponent"
import moment from "moment"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import commonHelpers from "../../helpers/commonHelpers"
import AlertComponent from "../alertComponent";

class ViewAppointments extends Component {
  constructor() {
    super();
    this.headerData = {
      name: "Patient Engagement",
      buttons: [{ name: 'SMS Template', path: '/smsTemplate' }, { name: 'Email Template', path: '/emailTemplate' }, { name: 'Logout', path: '/logout' }]
    };
    this.columns = [
      {
        name: "Patient Name",
        selector: "patientName",
        className: "patientName",
        align: "left",
        sortable: true,
        width: 10
      },
      {
        name: "Practitioner Name",
        selector: "practitionerName",
        className: "practitionerName",
        align: "left",
        sortable: true,
        width: 10
      },
      {
        name: "Location",
        selector: "location",
        className: "location",
        align: "left",
        sortable: true,
        width: 10
      },
      {
        name: "Appointment Date",
        selector: "appointmentDate",
        className: "appointmentDate",
        align: "left",
        sortable: true,
        width: 10
      },
      {
        name: "SMS Sent",
        selector: "isMessageSent",
        className: "isMessageSent",
        align: "left",
        cell: record => {
          if (record.isMessageSent) {
            return (
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="fa-2x fa-fw text-success" />
              </div>
            );
          } else {
            return (
              <div>
                <FontAwesomeIcon icon={faTimesCircle} className="fa-2x fa-fw text-danger" />
              </div>
            );
          }
        },
        sortable: true,
        width: 10
      },
      {
        name: "Mail Sent",
        selector: "isEmailSent",
        className: "isEmailSent",
        cell: record => {
          if (record.isEmailSent) {
            return (
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="fa-2x fa-fw text-success" />
              </div>
            );
          } else {
            return (
              <div>
                <FontAwesomeIcon icon={faTimesCircle} className="fa-2x fa-fw text-danger" />
              </div>
            );
          }
        },
        align: "left",
        sortable: true,
        width: 10
      },
      {
        name: "Reschedule Date",
        className: "rescheduleDate",
        align: "left",
        sortable: true,
        width: 10,
        selector: "rescheduleDate",
        cell: record => {
         return <div> {record.isReschedule ? (moment(record.rescheduleDate).format('YYYY/MM/DD')):'-'} <br/> {record.timeSlot} </div>
        }
      }
    ];
  }
  componentDidMount() {
    commonHelpers.verifyRoute()
    const self = this;
    let token = localStorage.getItem('token')
    axios
      .get(`${process.env.REACT_APP_API_URL}/appointment/missingAppointment`, { 'headers': { 'Authorization': token } })
      .then(res => {
        let data = res.data.map(refData => {
          refData.appointmentDate = moment(refData.appointmentDate).format('YYYY/MM/DD hh:mm')
          return refData
        })
        self.setState({ data: data });
      });
  }
  render() {
    let htmlData = (
      <>
        <div className="container">
          <AlertComponent
            msg={{
              msgLoading: "Fetching pending appointments...",
            }}
          />
        </div>
      </>
    );
    if (this.state != null && this.state != undefined) {
      htmlData = (
        <DataTable
          columns={this.columns}
          data={this.state.data}
          striped={true}
          pointerOnHover={true}
          highlightOnHover={true}
          responsive={true}
          pagination={true}
          paginationPerPage={20}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          border={true}
          theme="default"
          noDataComponent={
            <>
              <div className="container">
                <AlertComponent
                  msg={{
                    msgWarning: "No appointments found.",
                  }}
                />
              </div>
            </>
          }
        />
      );
    }
    return (
      <div>
        <HeaderComponent data={this.headerData}></HeaderComponent>
        <div className="row no-gutter">
          <div className="col-lg-12">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link className="nav-link aLink" to="/viewPatientReferral">
                  Patient Referral
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link active">Appointment</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">{htmlData}</div>
        </div>
      </div>
    );
  }
}

export default ViewAppointments;
