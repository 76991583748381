import React, { Fragment } from "react";
import "../../App.css";
import "./emailTemplate.css";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import helpers from "../../helpers/commonHelpers";
import browserStorageHelpers from "../../helpers/browserStorageHelper";
import EmailEditor from "react-email-editor";
import commonHelpers from "../../helpers/commonHelpers"
import HeaderComponent from "../headerComponent/headerComponent"


class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.headerData = {
      name: "Email Template",
      buttons: [{ name: 'Back', path: '/viewAppointments' },{ name: 'SMS Template', path: '/smsTemplate' }, { name: 'Logout', path: '/logout' }]
    };
    this.state = {
      msg: { msgLoading: "", msgError: "", msgSuccess: "", msgWarning: "" },
      smsMessage: "",
      emailType: "",
      redirect: false,
      redirectUrl: "/",
      error: false,
      disableLogin: true
    };
    this.onChange = this.onChange.bind(this);
    this.onDesignLoad = this.onDesignLoad.bind(this);
    this.count = 1;
  }

  componentDidMount = async () => {
    commonHelpers.verifyRoute()
    let _self = this;
    _self.setState({
      msg: helpers.setMsg("Checking for browser support...", "", "", "")
    });
    let supported = await browserStorageHelpers.checkSupport();
    if (supported != null) {
      _self.setState({
        msg: helpers.setMsg("", supported, "", "")
      });
    }
    if (supported == null) {
      _self.setState({
        msg: helpers.setMsg("", "", "", ""),
        disableLogin: false
      });
    }
    this.type = 1;
    axios
      .get(`${process.env.REACT_APP_API_URL}/emailTemplate/getEmailTemplate`, {
        params: {
          type: this.type
        }
      })
      .then(res => {
        let jsonTemplate = JSON.parse(res.data.jsonTemplate);
        console.log("res data: ", jsonTemplate);
        this.setState({
          emailMessage: res.data.template,
          jsonTemplate: jsonTemplate
        });
      });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/notificationTypes/getNotificationTypes`
      )
      .then(res => {
        console.log("getNotificationTypes: ", res.data);
        this.setState({ notificationTypes: res.data });
      });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "emailType") {
      this.getSMSTemplate(e.target.value);
    }
  }

  renderRedirect = props => {
    this.props.history.push("/ViewAppointments");
  };

  onsubmit(event) {
    event.preventDefault();
    var _self = this;
  }


  getSMSTemplate(smsType) {
    this.count = 1;
    console.log("smsType:", smsType);
    if (smsType == "Missed Appointment") {
      this.type = 1;
    } else if (smsType == "Lab Test Order") {
      this.type = 2;
    } else if (smsType == "CT Scan") {
      this.type = 3;
    } else if (smsType == "Referral") {
      this.type = 4;
    } else if (smsType == "Reminder") {
      this.type = 5;
    } else {
      this.type = 6;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/emailTemplate/getEmailTemplate`, {
        params: {
          type: this.type
        }
      })
      .then(res => {
        let jsonTemplate = JSON.parse(res.data.jsonTemplate);
        this.setState({
          emailMessage: res.data.template,
          jsonTemplate: jsonTemplate
        });
        this.onDesignLoad();
      });
  }


  render() {
    if (
      (this.state.jsonTemplate != null &&
        this.state.jsonTemplate != undefined) &&
      (this.state.notificationTypes != null &&
        this.state.notificationTypes != undefined)
    ) {
      // console.log("jsonTemplate Table loaded:")
      return (
        <div className="container">
          <HeaderComponent data={this.headerData}></HeaderComponent>

          {/* <h1>Email Template Editor</h1> */}
          {/* <div className="col-md-3">
          <Button style={{ backgroundColor: "#03c4eb" }} onClick={this.exportHtml}>Save</Button>
          </div> */}
          <Form>
            <Form.Group controlId="formBasicEmailType">
              <div className="row">
                <div className="col-md-5 offset-md-1"> <Form.Label>Select SMS Type</Form.Label></div>
              </div>
              {/* <Form.Label>Select SMS Type</Form.Label> */}
              <div className="row">
                <div className="col-md-1"><Button className="buttonStyle" onClick={this.exportHtml}>Save</Button></div>
                <div className="col-md-5">
                  <Form.Control
                    as="select"
                    onChange={this.onChange}
                    name="emailType"
                    value={this.state.emailType}
                    required
                  >
                    {this.state.notificationTypes.map((e, key) => {
                      return (
                        <option key={key} value={e.Reason}>
                          {e.Reason}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              </div>
            </Form.Group>
          </Form>
          <EmailEditor
            ref={editor => this.editor = editor}
            onDesignLoad={this.onDesignLoad}
          />
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }

  onLoad = data => {
    this.editor.loadDesign(this.state.jsonTemplate);
  };

  onDesignLoad() {
    if (this.count == 1) {
      console.log("onDesignLoad:", this.count, this.state.jsonTemplate)
      this.editor.loadDesign(this.state.jsonTemplate);
      this.count = 0
    }
  };

  renderRedirect = props => {
    this.props.history.push("/ViewAppointments");
  };

  exportHtml = () => {
    this.editor.exportHtml(data => {
      const { design, html } = data;
      console.log("html:", html);
      console.log("design:", JSON.stringify(design));
      const saveData = {
        emailMessage: html,
        jsonTemplate: JSON.stringify(design),
        type: this.type
      };
      console.log("user:", saveData, design);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/emailTemplate/updateEmailTemplate`,
          saveData
        )
        .then(response => {
          console.log("response:", response);
          this.renderRedirect();
        })
        .catch(err => {
          console.log("error");
        });
    });
  };
}
export default EmailTemplate;
