/* eslint-disable eqeqeq */
var commonHelper = {
  checkauth: function(err) {
    if (
      err.response.statusText === "Unauthorized" &&
      err.response.status === 401
    ) {
      window.location = "/";
    }
  },
  /**
   * Set error messages to be handled in react.
   * @param {string} loading Loading Message
   * @param {string} error Error Message
   * @param {string} success Success Message
   * @param {string} warning Warning Message
   * @returns {array} Message array containing msgLoadiing & msgError
   */
  setMsg: function(loading = "", error = "", success = "", warning = "") {
    let msg = {
      msgLoading: loading,
      msgError: error,
      msgSuccess: success,
      msgWarning: warning
    };
    return msg;
  },
  /**
   * Generate error message for exception caught in try-catch block.
   *
   * @param {object} err Error object caught in try-catch block.
   *
   * @returns {string} Error Message Json
   */
  errMessage: function(err) {
    let errorMsg = "Network Error";
    if (err.message != "Network Error") {
      errorMsg = err.response.data.error;
    }
    let msgErr = this.setMsg("", errorMsg, "", "");
    return msgErr;
  },
  /**
   * Generate token based on RFC4122 standards
   * @returns UUID based on RFC4122 standards
   */
  generateUUID: function(version = 4) {
    // Public Domain/MIT
    var d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  },
  /**
   * Generate Request token to be passed with every request to map request with response.
   * @returns Request Token
   */
  generateRequestToken: function(sessionId = 0) {
    let uuid = this.generateUUID();
    let requestToken = uuid;
    if (sessionId != 0) {
      requestToken = sessionId + "-0-" + this.generateUUID();
    }
    return requestToken;
  },
  manageString: str => {
    if (typeof str !== "string") return "";
    let tempStr = str.split(/(?=[A-Z])/).join(" ");
    return tempStr.charAt(0).toUpperCase() + tempStr.slice(1);
  },
  verifyRoute:()=>{
    if (
      !localStorage.getItem('token')
    ) {
      window.location = "/login";
    }
  }
};

export default commonHelper;
