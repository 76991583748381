import React, { Component } from "react";
import HeaderComponent from "../headerComponent/headerComponent";
import {
  Row,
  Col,
  Label,
  ListGroupItem,
  Card,
  ListGroup,
  CustomInput,
  Form,
  FormGroup,
  CardBody,
} from "reactstrap";

import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";

import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import moment from "moment";
import "./rescheduleAppointment.css";

class Rescheduleappointment extends Component {
  constructor() {
    super();
    this.headerData = {
      name: "Reschedule appointment",
      buttons: [],
    };
    this.state = {
      appointmentDate: new Date(),
      timeslot: "",
      appointmentData: {},
      appointmentId: "",
      rescheduleVia: "",
      timeslots: [],
      sweetAlert: false,
      isReschedule: 0,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    console.log("Values===", values.id, values.rescheduleVia);
    await this.setState({
      appointmentId: values.id,
      rescheduleVia: values.rescheduleVia,
    });
    this.getTimeSlot();
    this.getAppointment(values.id);
  }

  getTimeSlot() {
    let timeslots = [
      {
        id: 1,
        timeslot: "09:00 AM - 09:15 AM",
      },
      {
        id: 2,
        timeslot: "09:15 AM - 09:30 AM",
      },
      {
        id: 3,
        timeslot: "09:30 AM - 09:45 AM",
      },
      {
        id: 4,
        timeslot: "09:45 AM - 10:00 AM",
      },
      {
        id: 5,
        timeslot: "10:00 AM - 10:15 AM",
      },
      {
        id: 6,
        timeslot: "10:15 AM - 10:30 AM",
      },
      {
        id: 7,
        timeslot: "10:30 AM - 10:45 AM",
      },
      {
        id: 8,
        timeslot: "10:45 AM - 11:00 AM",
      },
      {
        id: 9,
        timeslot: "11:00 AM - 11:15 AM",
      },
      {
        id: 10,
        timeslot: "11:15 AM - 11:30 AM",
      },
      {
        id: 11,
        timeslot: "11:30 AM - 11:45 AM",
      },
      {
        id: 12,
        timeslot: "11:45 AM - 12:00 AM",
      },
    ];
    this.setState({ timeslots: timeslots });
  }

  handleDateChange(date) {
    this.setState({
      appointmentDate: date,
    });
  }
  toastMessage = (msg, type) =>
    (this.toastId = toast(msg, {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type: type,
    }));
  addTimeslot = (e) => {
    this.setState({ timeslot: e });
    if (this.state.startDate !== null) {
      this.setState({ sweetAlert: true });
    } else {
      this.toastMessage("Please select a date", "error");
    }
  };
  createAppointment = () => {
    let _self = this;
    let obj = {
      id: this.state.appointmentId,
      rescheduleDate: this.state.appointmentDate,
      type: this.state.rescheduleVia,
      timeSlot: this.state.timeslot,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/appointment/reschedule`, obj)
      .then((response) => {
        _self.toastMessage(response.data, "success");
        _self.setState({ sweetAlert: false });
        _self.getAppointment(_self.state.appointmentId);
        console.log(response);
      })
      .catch((err) => {
        _self.toastMessage("Failed to reschedule appointment", "error");
        console.log("Error", err);
      });
  };
  getAppointment(id) {
    let _self = this;
    axios
      .get(`${process.env.REACT_APP_API_URL}/appointment/getAppointment/${id}`)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            appointmentData: res.data,
            isReschedule: res.data.isReschedule ? 0 : 1,
          });
        } else {
          this.setState({
            isReschedule: 2,
          });
        }
      })
      .catch((err) => {
        console.log("err===========", err);
      });
  }
  render() {
    let appointment = this.state.appointmentData;
    let htmlData = [];
    if (this.state.isReschedule == 1) {
      htmlData.push(
        <Row>
          <div className="col-md-4">
            <Card>
              <div className="card-header">Provider</div>
              <CardBody className="text-center">
                {this.state.appointmentData.practitionerName}
              </CardBody>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <div className="card-header">Select Date</div>
              <CardBody className="text-center">
                <DatePicker
                  className="form-control"
                  selected={this.state.appointmentDate}
                  onChange={this.handleDateChange}
                  minDate={new Date()}
                  calendarClassName="no-shadow"
                  inline
                />
              </CardBody>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <div className="card-header">
                Selected Date:{" "}
                {moment(this.state.appointmentDate).format("MM-DD-YYYY")}
              </div>
              <CardBody className="text-center">
                <ListGroup className="todo-list-wrapper" flush>
                  <Form>
                    <FormGroup>
                      {this.state.timeslots.map((value, key) => {
                        return (
                          <ListGroupItem key={key}>
                            <div className="todo-indicator bg-warning" />
                            <div className="widget-content p-0">
                              <div className="widget-content-wrapper">
                                <div className="widget-content-left mr-2"></div>
                                <div className="widget-content-left">
                                  <div className="widget-heading">
                                    {value.timeslot}
                                  </div>
                                </div>
                                <div className="widget-content-right widget-content-actions1">
                                  <div>
                                    <CustomInput
                                      type="radio"
                                      id={"radio_" + key}
                                      name="customRadio"
                                      onClick={() =>
                                        this.addTimeslot(value.timeslot)
                                      }
                                      title="Select Timeslot"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        );
                      })}
                    </FormGroup>
                  </Form>
                </ListGroup>
              </CardBody>
            </Card>
          </div>
        </Row>
      );
    } else if (this.state.isReschedule == 0) {
      htmlData.push(
        <div>
          <Card>
            <CardBody className="text-center">
              <h6>
                Appointment reschedule on{" "}
                {moment(appointment.rescheduleDate).format("MM-DD-YYYY")}{" "}
                {appointment.timeSlot}
              </h6>
            </CardBody>
          </Card>
        </div>
      );
    } else {
      htmlData.push(
        <div>
          <Card>
            <CardBody className="text-center">
              <h6>Appointment Not Available</h6>
            </CardBody>
          </Card>
        </div>
      );
    }
    return (
      <div>
        <ToastContainer />
        <SweetAlert
          title="Are you sure?"
          confirmButtonColor=""
          confirmButtonText="Yes"
          show={this.state.sweetAlert}
          text={
            "Selected Date: " +
            moment(this.state.appointmentDate).format("MM-DD-YYYY") +
            " Time: " +
            this.state.timeslot
          }
          showCancelButton
          onConfirm={this.createAppointment.bind(this)}
          onCancel={() => this.setState({ sweetAlert: false })}
        />
        <HeaderComponent data={this.headerData}></HeaderComponent>
        <div className="container">{htmlData}</div>
      </div>
    );
  }
}

export default Rescheduleappointment;
