import React, { Fragment } from "react";
import "../../App.css";
import axios from "axios";
import { Container, Card, Button, Row, Col, Form } from "react-bootstrap";
import { CardBody } from "reactstrap";
import helpers from "../../helpers/commonHelpers";
import browserStorageHelpers from "../../helpers/browserStorageHelper";
import commonHelpers from "../../helpers/commonHelpers"
import HeaderComponent from "../headerComponent/headerComponent"

class SMSTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.headerData = {
      name: "SMS Template",
      buttons: [{ name: 'Back', path: '/viewAppointments' },{ name: 'Email Template', path: '/emailTemplate' }, { name: 'Logout', path: '/logout' }]
    };
    this.state = {
      msg: { msgLoading: "", msgError: "", msgSuccess: "", msgWarning: "" },
      smsMessage: "",
      smsType: "",
      redirect: false,
      redirectUrl: "/",
      error: false,
      disableLogin: true
    };
    this.smsType = [
      {
        Reason: "Missed Appointment"
      },
      {
        Reason: "Referral"
      }
    ];
    this.onChange = this.onChange.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
  }

  componentDidMount = async () => {
    commonHelpers.verifyRoute()
    const self = this;
    self.setState({
      msg: helpers.setMsg("Checking for browser support...", "", "", "")
    });
    let supported = await browserStorageHelpers.checkSupport();
    if (supported != null) {
      self.setState({
        msg: helpers.setMsg("", supported, "", "")
      });
    }
    if (supported == null) {
      self.setState({
        msg: helpers.setMsg("", "", "", ""),
        disableLogin: false
      });
    }

    this.type = 1;
    axios
      .get(`${process.env.REACT_APP_API_URL}/smsTemplate/getSMSTemplate`, {
        params: {
          type: this.type
        }
      })
      .then(res => {
        console.log("res data: ", res.data);
        this.setState({ smsMessage: res.data.template });
      });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/notificationTypes/getNotificationTypes`
      )
      .then(res => {
        console.log("getNotificationTypes: ", res.data);
        self.setState({ notificationTypes: res.data });
      });
  };

  onChange(e) {
    console.log("e.target:", e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "smsType") {
      this.getSMSTemplate(e.target.value);
    }
  }

  getSMSTemplate(smsType) {
    console.log("smsType:", smsType);
    const self = this;
    if (smsType == "Missed Appointment") {
      this.type = 1;
    } else if (smsType == "Lab Test Order") {
      this.type = 2;
    } else if (smsType == "CT Scan") {
      this.type = 3;
    } else if (smsType == "Referral") {
      this.type = 4;
    } else if (smsType == "Reminder") {
      this.type = 5;
    } else {
      this.type = 6;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/smsTemplate/getSMSTemplate`, {
        params: {
          type: this.type
        }
      })
      .then(res => {
        console.log("res data: ", res.data);
        self.setState({ data: res.data });
        this.setState({ smsMessage: res.data.template });
      });
  }

  renderRedirect = props => {
    this.props.history.push("/ViewAppointments");
  };

  onsubmit(event) {
    event.preventDefault();
    var _self = this;
    console.log("this.type:", this.type);
    var requestToken = helpers.generateRequestToken();
    const saveData = {
      messageBody: _self.state.smsMessage,
      type: this.type
    };
    console.log("user:", saveData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/smsTemplate/updateSMSTemplate`,
        saveData
      )
      .then(response => {
        console.log("response:", response);
        this.renderRedirect();
      })
      .catch(err => {
        console.log("error");
      });
  }

  render() {
    if (
      this.state.notificationTypes != null &&
      this.state.notificationTypes != undefined
    ) {
      console.log(
        "this.state.notificationTypes:",
        this.state.notificationTypes
      );
      return (
        <div className="container">
          <HeaderComponent data={this.headerData}></HeaderComponent>
          <div style={{ marginTop: "10%" }}>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* <div className="col-md-6 offset-md-4"> */}
                <Card
                  border="primary"
                  style={{ border: "1px solid #5d585675" }}
                >
                  <CardBody>
                    <Form onSubmit={this.onsubmit}>
                      <h4 className="mb-4">
                        <center>SMS Template</center>
                      </h4>
                      <Form.Group as={Row} controlId="formBasicSMSType">
                        <Col sm="12">
                          <Form.Label>Select SMS Type</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.onChange}
                            name="smsType"
                            value={this.state.smsType}
                            required
                          >
                            {this.state.notificationTypes.map((e, key) => {
                              return (
                                <option key={key} value={e.Reason}>
                                  {e.Reason}
                                </option>
                              );
                            })}
                          </Form.Control>
                          <br />
                        </Col>
                      </Form.Group>{" "}
                      <Form.Group as={Row} controlId="formBasicSMSMessage">
                        <Col sm="12">
                          <Form.Label>Enter SMS Template</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            placeholder="Enter SMS Template"
                            onChange={this.onChange}
                            name="smsMessage"
                            value={this.state.smsMessage}
                            required
                          />
                          <br />
                          <Button
                            // className="customBtn"
                            style={{ backgroundColor: "#03c4eb" }}
                            type="submit"
                            disabled={this.state.disableLogin}
                          >
                            {/* <FontAwesomeIcon icon={faSignInAlt} className="fa-fw" />{" "} */}
                            Save
                          </Button>
                        </Col>
                      </Form.Group>{" "}
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}
export default SMSTemplate;
