import React, { useState, useEffect } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import logo from "../../Logo-02.png";

export default function HeaderComponent(props) {
  let logoPath = logo;
  if (
    typeof props.data.logo != "undefined" &&
    props.data.logo != null &&
    props.data.logo != ""
  ) {
    logoPath = props.data.logo;
  }

  return (
    <header className="tableHeader">
      <div className="row no-gutter">
        <div className="col-md-4 text-center">
          <img
            src={logoPath}
            alt="Visolyr"
            style={{
              width: "250px",
            }}
          />
        </div>
        <div className="col-md-4 text-center">{props.data.name}</div>
        <div className="col-md-4 text-center">
          {props.data.buttons.map((val) => (
            <Link className=" button" to={val.path}>
              {val.name}
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
}
