import React from "react";
import "./App.css";
import ViewAppointments from "./components/viewAppointments/viewAppointments";
import ViewPatientReferral from "./components/viewPatientReferral/viewPatientReferral";
import RescheduleAppointment from "./components/rescheduleAppointment/rescheduleAppointment";
import ScheduleAppointment from "./components/scheduleAppointment/scheduleAppointment";
import Login from "./login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import EmailTemplate from "./components/emailTemplate/emailTemplate";
import SMSTemplate from "./components/smsTemplate/smsTemplate";
import Logout from "./logout"
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/Login" component={Login} exact />
        <Route path="/ViewAppointments" component={ViewAppointments} exact />
        <Route
          path="/viewPatientReferral"
          component={ViewPatientReferral}
          exact
        />
        <Route
          path="/rescheduleAppointment"
          component={RescheduleAppointment}
          exact
        />
         <Route
          path="/scheduleAppointment"
          component={ScheduleAppointment}
          exact
        />
        <Route path="/emailTemplate" component={EmailTemplate} exact />
        <Route path="/smsTemplate" component={SMSTemplate} exact />
        <Route path="/logout" component={Logout} exact />
      </Switch>
    </BrowserRouter>
  );
  // return <FirstPage />;
}

export default App;
