import React, { Fragment } from "react";
import "./App.css";
import axios from "axios";
import { Container, Card, Button, Row, Col, Form } from "react-bootstrap";
import { CardBody } from "reactstrap";
import helpers from "./helpers/commonHelpers";
import browserStorageHelpers from "./helpers/browserStorageHelper";
import logo from "./Logo-02.png";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: { msgLoading: "", msgError: "", msgSuccess: "", msgWarning: "" },
      email: "",
      password: "",
      redirect: false,
      redirectUrl: "/",
      error: false,
      disableLogin: true,
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.msgBlank = helpers.setMsg("", "");
    this.msg = helpers.setMsg("Fetching data...", "");
  }

  componentDidMount = async () => {
    let _self = this;
    if (localStorage.getItem("token")) {
      this.renderRedirect();
    }
    _self.setState({
      msg: helpers.setMsg("Checking for browser support...", "", "", ""),
    });
    let supported = await browserStorageHelpers.checkSupport();
    if (supported != null) {
      _self.setState({
        msg: helpers.setMsg("", supported, "", ""),
      });
    }
    if (supported == null) {
      _self.setState({
        msg: helpers.setMsg("", "", "", ""),
        disableLogin: false,
      });
    }
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderRedirect = (props) => {
    this.props.history.push("/ViewAppointments");
  };

  async handleSubmit(event) {
    event.preventDefault();
    var _self = this;
    _self.setState({
      msg: helpers.setMsg("Logging in..."),
    });
    var requestToken = helpers.generateRequestToken();
    const user = {
      email: _self.state.email,
      password: _self.state.password,
      request_token: requestToken,
    };
    console.log(user);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/admin/login`, user)
      .then((response) => {
        console.log(response);
        let resultData = response.data.result;
        if (!resultData.auth) {
          let msgErr = helpers.setMsg("", "Authentication failed.", "", "");
          _self.setState({
            msg: msgErr,
          });
        }
        if (resultData.auth) {
          _self.setState({
            msg: helpers.setMsg("", "", "Login successful.", ""),
            error: true,
          });
          let uid = resultData.uid;
          let utoken = resultData.token;
          let redirect = true;
          /**
           * Admin Home Path
           */
          let redirectUrl = "/admin/home";
          browserStorageHelpers.setItem("token", utoken);
          browserStorageHelpers.setItem("uid", uid);
          _self.setState({ redirect, redirectUrl });
        }
      })
      .catch((err) => {
        let msgErr = helpers.errMessage(err);
        console.log("msgErr", msgErr);
        _self.setState({
          msg: msgErr,
          error: true,
        });
      });
  }

  onsubmit(event) {
    event.preventDefault();
    var _self = this;
    var requestToken = helpers.generateRequestToken();
    const user = {
      email: _self.state.email,
      password: _self.state.password,
      request_token: requestToken,
    };
    console.log("user:", user);
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/login`, user)
      .then((response) => {
        let resultData = response.data.result;
        let uid = resultData.uid;
        let utoken = resultData.token;
        browserStorageHelpers.setItem("token", utoken, "LS");
        browserStorageHelpers.setItem("uid", uid, "LS");
        this.renderRedirect();
      })
      .catch((err) => {
        console.log("error");
      });
  }

  render() {
    return (
      <Fragment>
        {/* {this.renderRedirect()} */}
        <Container>
          <Row>
            {/* <div className="col-md-3"></div> */}
            <div className="col-md-6 offset-md-3" style={{ marginTop: "10%" }}>
              {/* <AlertComponent msg={this.state.msg} /> */}
              <Card border="primary" style={{ border: "1px solid #5d585675" }}>
                <CardBody style={{ padding: "25px" }}>
                  <Form onSubmit={this.onsubmit}>
                    <h4 className="mb-4">
                      <center>
                        <img
                          src={logo}
                          alt="Visolyr"
                          style={{
                            width: "250px",
                          }}
                        />
                      </center>
                      <center>Admin Login</center>
                    </h4>
                    <Form.Group as={Row} controlId="formBasicEmail">
                      <Col sm="12">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          onChange={this.onChange}
                          name="email"
                          value={this.state.email}
                          required
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formBasicPassword">
                      <Col sm="12">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={this.state.password}
                          name="password"
                          onChange={this.onChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Button
                      className="customBtn"
                      type="submit"
                      disabled={this.state.disableLogin}
                    >
                      {/* <FontAwesomeIcon icon={faSignInAlt} className="fa-fw" />{" "} */}
                      Sign In
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default Login;
