import React, { Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import HeaderComponent from "../headerComponent/headerComponent"
import "./viewPatientReferral.css";
import moment from "moment"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import commonHelpers from "../../helpers/commonHelpers"
import AlertComponent from "../alertComponent";

class ViewPatientReferral extends Component {
  constructor() {
    super();
    this.headerData = {
      name: "Patient Engagement",
      buttons: [{ name: 'SMS Template', path: '/smsTemplate' }, { name: 'Email Template', path: '/emailTemplate' }, { name: 'Logout', path: '/logout' }]
    };
    this.columns = [
      {
        name: "Patient Name",
        selector: "patientName",
        className: "patientName",
        align: "left",
        sortable: true,
        width: 30
      },
      {
        name: "Requester Practitioner Name",
        selector: "requesterName",
        className: "requesterName",
        align: "left",
        sortable: true,
        width: 30
      },
      {
        name: "Performer Practitioner Name",
        selector: "performerName",
        className: "performerName",
        align: "left",
        sortable: true,
        width: 30
      },
      {
        name: "Referred On",
        selector: "authoredOn",
        className: "authoredOn",
        align: "left",
        sortable: true,
        width: 30
      },
      {
        name: "SMS Sent",
        selector: "isMessageSent",
        className: "isMessageSent",
        align: "left",
        cell: record => {
          if (record.isMessageSent) {
            return (
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="fa-2x fa-fw text-success" />
              </div>
            );
          } else {
            return (
              <div>
                <FontAwesomeIcon icon={faTimesCircle} className="fa-2x fa-fw text-danger" />
              </div>
            );
          }
        },
        sortable: true,
        width: 30
      },
      {
        name: "Mail Sent",
        selector: "isEmailSent",
        className: "isEmailSent",
        cell: record => {
          if (record.isEmailSent) {
            return (
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="fa-2x fa-fw text-success" />
              </div>
            );
          } else {
            return (
              <div>
                <FontAwesomeIcon icon={faTimesCircle} className="fa-2x fa-fw text-danger" />
              </div>
            );
          }
        },
        align: "left",
        sortable: true,
        width: 30
      },
      {
        name: "Appointment Date",
        className: "appointmentDate",
        align: "left",
        sortable: true,
        width: 30,
        selector: "appointmentDate",
        cell: record => {
          return <div> {record.isSchedule ? (moment(record.appointmentDate).format('YYYY/MM/DD')) : '-'} <br /> {record.timeSlot} </div>
        }
      }
    ];
  }
  componentDidMount() {
    commonHelpers.verifyRoute()
    const self = this;
    axios
      .get(`${process.env.REACT_APP_API_URL}/patientReferral/getPatientReferral`)
      .then(res => {
        let data = res.data.map(refData => {
          refData.authoredOn = moment(refData.authoredOn).format('YYYY/MM/DD')
          return refData
        })
        self.setState({ data: data });
      });
  }
  render() {
    let htmlData = (
      <>
        <div className="container">
          <AlertComponent
            msg={{
              msgLoading: "Fetching pending referrals...",
            }}
          />
        </div>
      </>
    );
    if (this.state != null && this.state != undefined) {
      htmlData = (
        <DataTable
          columns={this.columns}
          data={this.state.data}
          striped={true}
          pointerOnHover={true}
          highlightOnHover={true}
          responsive={true}
          pagination={true}
          paginationPerPage={20}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          border={true}
          theme="default"
          noDataComponent={
            <>
              <div className="container">
                <AlertComponent
                  msg={{
                    msgWarning: "No referrals found.",
                  }}
                />
              </div>
            </>
          }
        />
      );
    }
    return (
      <>
        <div>
          <HeaderComponent data={this.headerData}></HeaderComponent>
          <div className="row no-gutter">
            <div className="col-lg-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className="nav-link active">Patient Referral</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link aLink" to="/viewAppointments">
                    Appointment
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-12">{htmlData}</div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewPatientReferral;
