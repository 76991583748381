import React, { Fragment, Component } from "react";
import { Col, Row } from "reactstrap";
import { Redirect } from "react-router-dom";
import helpers from "./helpers/commonHelpers";
import AlertComponent from "./components/alertComponent";
import browserStorageHelpers from "./helpers/browserStorageHelper";
// import userTypes from "../../config/userTypes";

class Logout extends Component {
  constructor() {
    super();
    localStorage.removeItem("token");
    this.state = {
      msg: { msgLoading: "", msgError: "", msgSuccess: "", msgWarning: "" },
      redirect: false,
      redirectUrl: "/login"
    };
    this.renderRedirect = this.renderRedirect.bind(this);
  }
  componentDidMount() {
    var _self = this;
    _self.setState({
      msg: helpers.setMsg("Logging out...", "", "", "")
    });
    browserStorageHelpers.clear();
    var msg = "logged out successfully.";
    var redirect = true;
    // eslint-disable-next-line eqeqeq
    // if (uType != userTypes.Admin && uType != userTypes.Payer) {
    //   redirect = false;
    // }
    _self.setState({
      msg: helpers.setMsg("", "", msg, ""),
      redirect
    });
    browserStorageHelpers.clear();
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
  };
  render() {
    console.log(browserStorageHelpers.getItem("token"));
    return (
      <Fragment>
        {this.renderRedirect()}
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col
              lg="12"
              md="12"
              className="h-100 d-flex bg-white align-items-center"
            >
              <Col lg="4" md="8" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <div>
                  <Row>
                    <Col md={12}>
                      <AlertComponent msg={this.state.msg} />
                    </Col>
                  </Row>
                </div>
                <br />
                <div>
                  <p className="text-center">
                    {/* &copy;&nbsp;{moment().format("YYYY")}&nbsp; */}
                    <a
                      href={process.env.REACT_APP_COPYRIGHT_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {process.env.REACT_APP_COPYRIGHT_NAME}
                    </a>
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default Logout;
