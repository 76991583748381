/* eslint-disable eqeqeq */
/**
 * Storage type.
 * LS = localStorage
 * SS = sessionStorage
 */
const storageType = "SS";

var browserStorageHelper = {
  /**
   * Checks if localStorage & sessionStorage is supported by client browser.
   */
  checkSupport: () => {
    if (typeof Storage == "undefined") {
      return "Your browser doesn't support browser storage.";
    }
    if (
      !navigator.cookieEnabled ||
      typeof window.localStorage == "undefined" ||
      !window.localStorage
    ) {
      return "Your browser doesn't support localStorage or cookies.";
    }
    if (
      !navigator.cookieEnabled ||
      typeof window.sessionStorage == "undefined" ||
      !window.sessionStorage
    ) {
      return "Your browser doesn't support sessionStorage.";
    }
    return null;
  },
  /**
   * Set browser storage variable based on storageType.
   *
   * @param {string} key Variable name.
   * @param {string} value Variable value.
   * @param {string} type Browser storage type. LS = localStorage, SS = sessionStorage. Default is LS.
   *
   * @returns Nothing.
   */
  setItem: (key = "", value = "", type = storageType) => {
    if (type == "LS") {
      localStorage.setItem(key, value);
    }
    if (type == "SS") {
      sessionStorage.setItem(key, value);
    }
  },
  /**
   * Get browser storage variable based on storageType.
   *
   * @param {string} key Variable name.
   * @param {string} type Browser storage type. LS = localStorage, SS = sessionStorage. Default is LS.
   *
   * @returns Variable value from browser storage.
   */
  getItem: (key = "", type = storageType) => {
    let val = null;
    if (type == "LS") {
      val = localStorage.getItem(key);
    }
    if (type == "SS") {
      val = sessionStorage.getItem(key);
    }
    return val;
  },
  /**
   * Clear browser storage variables based on storageType.
   */
  clear: (type = storageType) => {
    if (type == "LS") {
      localStorage.clear();
    }
    if (type == "SS") {
      sessionStorage.clear();
    }
  }
};

export default browserStorageHelper;
